@import "../../../../assets/scss/mixin";
@import "../../../../assets/scss/variables";

/* Position and sizing of burger button */
.bm-burger-button {
  @include flex(center, center);
  width: 50px;
  height: 50px;
  position: relative;
  top: 0;
  left: 0;
  button {
    outline: none;
  }
  .iconMenu {
    width: 18px;
    color: map-get($colors, neutral-50);
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: map-get($colors, layout-grey-30);
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: map-get($colors, darwined-blue-70);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  color: map-get($colors, layout-grey-30);
  left: 8px;
  right: 0 !important;
  button {
    outline: none;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  background: map-get($colors, layout-grey-30);
  outline: none;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  width: 200px !important;
  outline: none;
}

/* General sidebar styles */
.bm-menu {
  @include text(0.875rem, layout-grey-90, bold);
  background: map-get($colors, foris-white);
  padding: 2.5rem 0;
  top: 0;
  border-top: 1px solid map-get($colors, layout-grey-30);
  height: 100%;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  @include text(1.08rem, layout-grey-90);
  padding: 0 1rem;
}

/* Individual item */
.bm-item {
  @include text(0.875rem, layout-grey-50);
  padding: 0 0 1rem !important;
  cursor: pointer;
  outline: none;
  &:hover,
  &:active {
    @include text(0.875rem, darwined-blue-50);
  }
}

.footer_menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 302px;
  background: map-get($colors, layout-grey-10);
  padding: 1rem !important;
  &-text {
    @include text(0.875rem, layout-grey-50);
    padding: 0 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    .log_out {
      width: 16px;
      color: map-get($colors, layout-grey-50);
      margin: 0 0.75rem 0 0;
    }
  }
}
